<template>
  <main>
    <h2>Czym jest Liga?</h2>
    <p>
      Liga Rozwoju jest studenckim projektem, działającym przy katedrze Zasobami
      Pracy na Uniwersytecie Ekonomicznym w Krakowie w ramach Koła Naukowego
      Rozwoju Osobistego.</p>
      <p class="bold"
        >Jej głównymi założeniami jest nauka przez grywalizację.</p
      >
      <p>W wybranych przez siebie Ligach Uczestnicy mogą rozwiązywać zadania
      przygotowane przez Prowadzących, tym samym zdobywający punkty, które
      pozwalają wygrać nagrody!</p>
      <p class="bold accent-color"
        >Najważniejsze jest jednak, że przez naukę pomagamy, gdyż wszystkie
        wpłaty za zapis przekazywane są na cel charytatywny.
      </p>
    
    <h2>Poznaj formę:</h2>
    <div class="tasks-list">
      <div>
        <h3>20</h3>
        <h4>Zadań dziennych</h4>
      </div>
      <div>
        <h3>4</h3>
        <h4>Zadania tygodniowe</h4>
      </div>
      <div>
        <h3>1</h3>
        <h4>Zadanie epickie</h4>
      </div>
    </div>
    <h2>Nasz zespół</h2>
    <p>
      Jest pełen intrygujących osobowości, ale jeśli chcesz dowiedzieć się więcej, to zapraszamy na nasz profil na Instagramie, gdzie każdy z nas został przedstawiony!
    </p>

  </main>
</template>
<script>
// import Spacer from '@/components/Spacer.vue'
// export default {
//   components:{
//     Spacer
//   }
// }
</script>
<style lang="sass" scoped>
.tasks-list
  display: flex
  width: 100%
  justify-content: space-around
  flex-wrap: wrap
  padding: 3rem
  > div
    text-align: center

.team-presentaion
  display: flex
  flex-wrap: wrap
  justify-content: center

.team-member
  width: max(20%, 512px)
  position: relative
  margin: 2rem
  img
    width: 100%
  div
    position: absolute
    bottom: 0
    z-index: 2
    width: 100%
    padding: 0.5rem

</style>