<template>
  <main>
    <h1>Linki do dokumentów powiązanych z Ligą Rozwoju:</h1>
    <h2>
      <a href="https://docs.google.com/document/d/e/2PACX-1vSrcFwC3zCeFlg4l_0_s_Ng61fKQB-ajB2SnO88bbVbhP202GOA2NFLDnTicUrodg/pub"> Regulamin dla Prowadzących</a>
    </h2>


  </main>
</template>
